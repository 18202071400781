import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation, Trans } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { MatchableType } from '../../../matchableType';
import renderDate from '../../../util/renderDate';

import Form from '../../section/form';
import FormInput from '../../section/form-input';
import MatchablePicker from '../../section/matchable-picker';

import { actions as DemandActions } from '../../../reducer/demand';

class DemandDetailsView extends Component {

  onChange (eventArgs) {

    const { demandId } = this.props.match.params;

    this.props.dispatch(
      DemandActions.set(
        demandId,
        eventArgs.target.name,
        eventArgs.target.value,
      )
    );

  }

  onCustomerSelected (eventArgs) {

    const { demandId } = this.props.match.params;

    this.props.dispatch(
      DemandActions.set(
        demandId,
        'customerId',
        eventArgs.matchableSelectedId,
      )
    );

  }

  onCommoditySelected (eventArgs) {

    const { demandId } = this.props.match.params;

    this.props.dispatch(
      DemandActions.set(
        demandId,
        'commodityId',
        eventArgs.matchableSelectedId,
      )
    );

  }

  onDiscard () {

    const { demandId } = this.props.match.params;

    this.props.dispatch(
      DemandActions.get(demandId)
    );

  }

  onDelete () {

    const { demandId } = this.props.match.params;

    this.props.dispatch(
      DemandActions.delete(demandId)
    );

  }

  onSubmit (eventArgs) {
    eventArgs.preventDefault();

    const { demandId } = this.props.match.params;
    const demand = this.props.demandStore.singles[demandId].data;

    if (demandId === 'new') {

      this.props.dispatch(
        DemandActions.create(demandId, demand)
      );

    } else {

      this.props.dispatch(
        DemandActions.persist(demandId, demand)
      );

    }

  }

  componentDidMount () {

    const { demandId } = this.props.match.params;

    if (demandId === 'new') {

      this.props.dispatch(
        DemandActions.new(demandId)
      );

    } else {

      this.props.dispatch(
        DemandActions.get(demandId)
      );

    }

  }

  renderDetails (demandData) {
    const demand = demandData.data;

    return (
      <Form onSubmit={ this.onSubmit.bind(this) }>

        <h1 className="pb-space">
        { demandData.created === true ?
          (
            <>{ this.props.i18n.t('label_create') }: { this.props.i18n.t('label_demand') }</>
          ):
          (
            <>
              { this.props.i18n.t('label_edit') }: { this.props.i18n.t('label_demand') }
              { demandData.altered === true ? (
                <FontAwesomeIcon icon="edit" />
              ) : (null) }
            </>
          )
        } </h1>

        <div className="pb-space">
          <Trans i18nKey="label_demand_emerged" />
          <FormInput
            type="text"
            value={ renderDate(demand.demandEmerged) }
            disabled={ true }
          />
        </div>

        <div className="pb-space">
          <Trans i18nKey="label_demand_quantity" />
          <FormInput
            type="text"
            name="demandedQuantity"
            value={ demand.demandedQuantity }
            onChange={ this.onChange.bind(this) }
          />
        </div>

        <div className="pb-space">
          <Trans i18nKey="label_customer" />
          <MatchablePicker
            name="all_customers"
            matchableType={ MatchableType.CUSTOMER }
            matchableSelectedId= { demand.customerId }
            onMatchableChanged={ this.onCustomerSelected.bind(this) }
          />
        </div>

        <div className="pb-space">
          <Trans i18nKey="label_commodity" />
          <MatchablePicker
            name="all_commodities"
            matchableType={ MatchableType.COMMODITY }
            matchableSelectedId= { demand.commodityId }
            onMatchableChanged={ this.onCommoditySelected.bind(this) }
          />
        </div>

        <div className="pb-space">
          <Trans i18nKey="demand_matched_commodity_code" />
          <FormInput
            type="text"
            name="matchedCommodityCode"
            value={ demand.matchedCommodityCode }
            onChange={ this.onChange.bind(this) }
          />
        </div>

        <div>{ demandData.created === true ?
          (
            <>
              <FormInput
                type="submit"
                value={ this.props.i18n.t('label_create') }
                pbStyle="success"
              />
            </>
          ):
          (
            <>
              { demandData.altered === true ? (
                <>
                  <FormInput
                    type="submit"
                    value={ this.props.i18n.t('label_save') }
                    pbStyle="success"
                  />
                  <FormInput
                    type="button"
                    value={ this.props.i18n.t('label_discard') }
                    onClick={ this.onDiscard.bind(this) }
                    pbStyle="warning"
                  />
                </>
              ) : (null) }
              <FormInput
                type="button"
                value={ this.props.i18n.t('label_delete') }
                onClick={ this.onDelete.bind(this) }
                pbStyle="danger"
              />
            </>
          )
        }
        <FormInput
          type="button"
          value={ this.props.i18n.t('label_back') }
          onClick={ this.props.history.goBack }
        />
        </div>

      </Form>
    );

  }

  renderFetching () {

    return (
      <div className="pb-group">
        <h1>
          <Trans i18nKey="label_fetching" />
        </h1>
      </div>
    );

  }

  renderError () {

    return (
      <div className="pb-group">
        <h1>
          <Trans i18nKey="label_error" />
        </h1>
      </div>
    );

  }

  render () {

    const { demandId } = this.props.match.params;
    const demand = { ...this.props.demandStore.singles[demandId] };

    if (!demand || demand.fetching) {

      return this.renderFetching();

    }

    if (demand.deleted) {

      this.props.history.push('/demand');

    }

    if (demand.error === null) {

      return this.renderDetails(demand);

    }

    return this.renderError();

  }


}

const enhance = compose(
  withTranslation(),
  withRouter,
  connect((store) => ({

    demandStore: store.DemandReducer,

  }))
);

export default enhance(DemandDetailsView);
