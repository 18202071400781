import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation, Trans } from "react-i18next";

import FormSelect from '../form-select';

import { actions as MatchableActions } from '../../../reducer/matchable';
import { matchableTypeToLocalPaths } from '../../../matchableType';

class MatchablePicker extends Component {

  componentDidMount () {

    const name = this.props.name;
    const matchableType = this.props.matchableType;

    this.props.dispatch(
      MatchableActions.list(name, matchableType)
    );

  }

  onMatchableChanged (eventArgs) {

    const matchableSelectedId = eventArgs.target.value;
    const matchables = this.props.matchableStore.collections[this.props.name];

    for (const matchable of matchables.data) {

      if (matchable.id === matchableSelectedId) {

        this.props.onMatchableChanged({

          name: this.props.name,
          matchableType: this.props.matchableType,
          matchableSelectedId,
          matchableSelected: matchable

        });

        return;

      }

    }

    this.props.onMatchableChanged({

      name: this.props.name,
      matchableType: this.props.matchableType,
      matchableSelectedId,

    });

  }

  renderList (matchables) {

    let matchableSelectedId = this.props.matchableSelectedId;
    let matchableSelected = null;

    if (matchableSelectedId) {

      matchableSelected = matchables.data.find((matchable) => matchable.id === matchableSelectedId);

    } else {

      matchableSelectedId = '';

    }

    return (
      <div className="pb-flex" style={ { 'align-items': 'end' } }>
        <FormSelect
          value={ matchableSelectedId }
          onChange={ this.onMatchableChanged.bind(this) }
        >
          <option value="" disabled={ true }>{ this.props.i18n.t('label_select') }</option>
          { matchables.data.map((matchable, key) =>
            <option key={ key } value={ matchable.id }>
              { matchable.matchCode } ({ matchable.name })
            </option>
          )}
        </FormSelect>
        { matchableSelected ? (
            <Link
              className="pb-button"
              to={ `${matchableTypeToLocalPaths(this.props.matchableType)}/${matchableSelected.id}` }
            >
              <Trans i18nKey="label_show" />
            </Link>
        ) : (null) }

      </div>
    );

  }

  renderFetching () {

    return (<Trans i18nKey="label_fetching" />);

  }

  renderError () {

    return (<Trans i18nKey="label_error" />);

  }

  render () {

    const matchables = this.props.matchableStore.collections[this.props.name];

    if (!matchables || matchables.fetching) {

      return this.renderFetching();

    }

    if (matchables.error === null) {

      return this.renderList(matchables);

    }

    return this.renderError();

  }

}

const enhance = compose(
  withTranslation(),
  connect((store) => ({
    matchableStore: store.MatchableReducer,
  }))
);

export default enhance(MatchablePicker);
