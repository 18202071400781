import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { withTranslation, Trans } from "react-i18next";

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { defaultColDef } from '../../../agGridHelpers';

import { MatchableType } from '../../../matchableType';

import { actions as MatchableActions } from '../../../reducer/matchable';

class CustomerListView extends Component {

  componentDidMount () {

    this.props.dispatch(
      MatchableActions.list('all_customers', MatchableType.CUSTOMER)
    );

  }

  onCellDoubleClicked (eventArgs) {

    this.props.history.push(`customer/${eventArgs.data.id}`);

  }

  renderList (matchablesData) {

    const columnDefinitions = [
      { headerName: 'Match Code', field: 'matchCode', sortable: true, filter: true },
      { headerName: 'Name', field: 'name', sortable: true, filter: true },
      { headerName: 'Postal Code', field: 'postalCode', sortable: true, filter: true },
    ];

    return (
      <>
      <div className="pb-space pb-flex">
        <Link className="pb-button" to="/customer/new">{ this.props.i18n.t('label_create') }</Link>
      </div>

      <div className="ag-theme-balham">
        <AgGridReact
          defaultColDef={ defaultColDef }
          columnDefs={ columnDefinitions }
          rowData={ matchablesData.data }
          onCellDoubleClicked={ this.onCellDoubleClicked.bind(this) }
        />
      </div>
      </>
    );

  }

  renderFetching () {

    return (<Trans i18nKey="label_fetching" />);

  }

  renderError () {

    return (<Trans i18nKey="label_error" />);

  }

  render () {

    const matchables = { ...this.props.matchableStore.collections['all_customers'] };

    if (!matchables || matchables.fetching) {

      return this.renderFetching();

    }

    if (matchables.error === null) {

      return this.renderList(matchables);

    }

    return this.renderError();

  }

}

const enhance = compose(
  withTranslation(),
  withRouter,
  connect((store) => ({

    matchableStore: store.MatchableReducer,

  }))
);

export default enhance(CustomerListView);
