import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation, Trans } from "react-i18next";

import { MatchableType } from '../../../matchableType';

import FormSelect from '../../section/form-select';
import MatchablePicker from '../../section/matchable-picker';

import { actions as FormActions } from '../../../reducer/form';
import { actions as DemandBulkActions } from '../../../reducer/demand-bulk';

const DEMAND_BULK_VIEW_FORM = 'DEMAND_BULK_VIEW_FORM';

class DemandBulkView extends Component {

  onCustomerSelected (eventArgs) {

    this.props.dispatch(FormActions.set(
      DEMAND_BULK_VIEW_FORM,
      'selectedCustomerId',
      eventArgs.matchableSelectedId,
    ));

  }

  onTableData (eventArgs) {

    const tableData = eventArgs.target.value;

    this.props.dispatch(FormActions.set(
      DEMAND_BULK_VIEW_FORM,
      'tableData',
      tableData,
    ));

    const bareRows = tableData.split('\n');
    let tableRows = [];

    for (let bareRow of bareRows) {

      tableRows.push(bareRow.split('\t'));

    }

    this.props.dispatch(FormActions.set(
      DEMAND_BULK_VIEW_FORM,
      'tableRows',
      tableRows,
    ));

  }

  columnMappingSelected (eventArgs) {

    this.props.dispatch(FormActions.set(
      DEMAND_BULK_VIEW_FORM,
      eventArgs.target.name,
      parseInt(eventArgs.target.value),
    ));

  }

  onCreate () {

    const form = { ...this.props.formStore.forms[DEMAND_BULK_VIEW_FORM] };
    const selectedCustomerId = form['selectedCustomerId'];

    let demandRows = [ ...form['tableRows'] ];
    demandRows.shift(); // Shift first row: headers

    let demands = [];

    for (let row of demandRows) {

      let demand = {
        externalMatchCode: row[form['externalMatchCodeColumnIndex']],
        demandedQuantity: parseFloat(row[form['demandedQuantityColumnIndex']]),
        customerId: selectedCustomerId,
      };

      demands.push(demand);

    }

    this.props.dispatch(DemandBulkActions.bulkCreate(demands));

  }

  renderSelectColumnMapping (columnName, columnIndex) {

    const form = { ...this.props.formStore.forms[DEMAND_BULK_VIEW_FORM] };
    const tableRows = form['tableRows'];
    const firstRowCells = tableRows[0];

    let selectedIndex = -1;

    if (Number.isInteger(columnIndex)) {
      selectedIndex = columnIndex;
    }

    return (
      <FormSelect
        name={ columnName }
        onChange={ this.columnMappingSelected.bind(this) }
        value={ selectedIndex }
      >

        <option value={ -1 } disabled={ true }>
          { this.props.i18n.t('label_not_selected') }
        </option>

        { firstRowCells.map((cell, key) =>
          <option key={ key } value={ key }>
            {this.props.i18n.t('label_column')} { key + 1 }: &#34;{ cell }&#34;
          </option>
        ) }

      </FormSelect>
    );

  }

  render () {

    const form = { ...this.props.formStore.forms[DEMAND_BULK_VIEW_FORM] };
    const selectedCustomerId = form['selectedCustomerId'];
    const tableData = form['tableData'];
    const tableRows = form['tableRows'];
    const bulkCreateStatus = this.props.demandBulkStore;

    if (bulkCreateStatus.fetching === true) {

      return 'Processing ...';

    }

    if (bulkCreateStatus.error === true) {

      return 'Error';

    }

    if (bulkCreateStatus.latestDemandEmerged) {

      this.props.history.push('/demand', {
        lastDate: bulkCreateStatus.latestDemandEmerged
      });

      this.props.dispatch(FormActions.reset(
        DEMAND_BULK_VIEW_FORM
      ));

      return null;

    }

    return (
      <div>
        <div className="pb-tile">

          <p className="pb-space">
            <Trans i18nKey="demand_bulk_step_1_select_customer" />
          </p>
          <MatchablePicker
            name="all_customers"
            matchableType={ MatchableType.CUSTOMER }
            matchableSelectedId= { selectedCustomerId }
            onMatchableChanged={ this.onCustomerSelected.bind(this) }
          />

        </div>

        { selectedCustomerId ? (
          <>
          <div className="pb-tile">

            <p className="pb-space">
              <Trans i18nKey="demand_bulk_step_2_paste_table" />
            </p>
            <textarea
              className="pb-textarea"
              value={ tableData }
              onChange={ this.onTableData.bind(this) }
            />

          </div>

          { tableRows && tableRows.length > 0 ? (
            <>
            <div className="pb-tile">
              <p className="pb-space">
                <Trans i18nKey="demand_bulk_step_3_match_columns" />
              </p>

              <div className="pb-space">

                <Trans i18nKey="label_external_match_code" />
                { this.renderSelectColumnMapping('externalMatchCodeColumnIndex', form['externalMatchCodeColumnIndex']) }

              </div>

              <div className="pb-space">

                <Trans i18nKey="label_demand_quantity" />
                { this.renderSelectColumnMapping('demandedQuantityColumnIndex', form['demandedQuantityColumnIndex']) }

              </div>

            </div>

            <div className="pb-tile">
              <button className="pb-button" onClick={ this.onCreate.bind(this) }>
                <Trans i18nKey="label_create" />
              </button>
            </div>
            </>
          ) : (null) }

          </>
        ) : (null) }

      </div>
    );

  }

}

const enhance = compose(
  withTranslation(),
  connect((store) => ({

    formStore: store.FormReducer,
    demandBulkStore: store.DemandBulkReducer,

  }))
);

export default enhance(DemandBulkView);
