import React, { Component } from 'react';
import { compose } from 'redux';
import { withRouter, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from "react-i18next";

import './style.css';

class MenuMain extends Component {

  renderLink (to, label, icon) {

    let classNames = [ 'pb-menu-main__link' ];

    if (this.props.location.pathname === to ||
      this.props.location.pathname.indexOf(`${to}/`) === 0) {
      classNames.push('pb-menu-main__link--active');
    }

    return (
      <Link className={ classNames.join(' ') } to={ to }>
        <FontAwesomeIcon className="pb-menu-main__link__image" icon={ icon } />
        <Trans i18nKey={ label } />
      </Link>
    );

  }

  render () {
    return (
      <nav className="pb-menu-main">
        <ul className="pb-menu-main__list">

          <li>
            { this.renderLink('/demand', 'label_demand', 'dolly') }
          </li>
          <li>
            { this.renderLink('/commodity', 'label_commodities', 'boxes') }
          </li>
          <li>
            { this.renderLink('/customer', 'label_customers', 'address-card') }
          </li>
          <li>
            { this.renderLink('/customerCommodity', 'label_customer_commodities', 'link') }
          </li>
        </ul>
      </nav>
    );

  }

}

const enhance = compose(
  withRouter,
);

export default enhance(MenuMain);
