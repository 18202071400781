
export const defaultColDef = {

  resizable: true,

};

export function matchableValueFormatter (params) {

  if (params && params.value) {

    if (typeof params.value === 'string') {

      return params.value;

    }

    return `${params.value.matchCode} (${params.value.name})`;

  }

  return '';

}

export function matchableKeyCreator (params) {

  if (params && params.value) {

    return params.value.name;

  }

  return '';

}

export function valueFormatterLongDateTime (params) {

  if (params && params.value) {

    let date = new Date(params.value);
    return date.toLocaleString('de-AT', { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' });

  }

  return '';

}

export function dateFilterComparator (filterLocalDateAtMidnight, dateAsString) {

  if (dateAsString === null) return -1;

  var cellDate = new Date(dateAsString);

  cellDate.setHours(0);
  cellDate.setMinutes(0);
  cellDate.setSeconds(0);
  cellDate.setMilliseconds(0);

  if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
    return 0;
  }
  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  }
  if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  }

}

export function dateKeyCreatorMonthYear (params) {

  if (params && params.value) {

    let date = new Date(params.value);
    return date.toLocaleString('de-AT', { year: 'numeric', month: 'long' });

  }

  return '';

}


export function dateValueFormatterMonthYear (params) {

  if (params && params.value) {

    let date = new Date(params.value);

    if (isNaN(date)) {

      return params.value;

    } else {

      return date.toLocaleString('de-AT', { year: 'numeric', month: 'long' });

    }

  }

  return '';

}
