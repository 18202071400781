import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    en: {
      translations: {
        label_actions: 'Actions',
        label_show: 'Show',
        label_create: 'Create',
        label_create_commodity: 'Create commodity',
        label_create_customer: 'Create customer',
        label_select: 'Select...',
        label_back: 'Back',
        label_not_selected: 'Not selected',
        label_edit: 'Edit',
        label_save: 'Save',
        label_discard: 'Discard changes',
        label_delete: 'Delete',
        label_saved: 'Saved',
        label_column: 'Column',
        label_demand: 'Demand',
        label_demands: 'Demands',
        label_commodity: 'Commodity',
        label_commodities: 'Commodities',
        label_customer: 'Customer',
        label_customers: 'Customers',
        label_customer_commodities: 'Commodity/Customer Relationships',
        label_customer_commodity: 'Commodity/Customer Relationship',
        label_categories: 'Categories',
        label_external_match_code: 'External match code',
        label_external_match_codes: 'External match codes',
        label_filter_last_month: 'Filter last month',
        label_filter_last_3_month: 'Filter last 3 months',
        // label_external_match_code_select: 'Select external match code',
        label_internal_match_code: 'Internal match code',
        // label_internal_match_code_select: 'Select internal match code',
        label_select_customer: 'Select customer',
        label_demand_quantity: 'Demanded quantity',
        // label_demand_quantity_select: 'Select demanded quantity',
        label_name: 'Name',
        label_postal_code: 'Postal code',
        label_sales_closing_price: 'Sales closing price',
        label_sales_distribution_price: 'Sales distribution price',
        label_sales_fixed_price: 'Sales fixed price',
        label_rematch: 'Rematch',
        label_add_external_matchcode: 'Add external match code',
        label_year_month_group: 'Year, Month',
        label_demand_emerged: 'Demand emerged',
        label_fetching: 'Loading ...',
        label_error: 'Error',
        demand_matched_commodity_code: 'Matched commodity code',
        demand_bulk_create: 'Bulk create demands',
        demand_bulk_step_1_select_customer: '1. Select customer',
        demand_bulk_step_2_paste_table: '2. Paste table data',
        demand_bulk_step_3_match_columns: '3. Match column headers',
        demand_bulk_status_created: 'Demand created',
        demand_bulk_status_created_and_relation: 'Demand and relationship between customer and commodity created',
        demand_bulk_status_customer_not_found: 'Customer not found',
        demand_bulk_status_external_mc_not_found: 'External match code not found for customer',
        demand_bulk_status_internal_mc_not_found: 'Internal match code not found',
        demand_bulk_status_internal_inconsistent_data: 'Inconsistent data',
        demand_bulk_status_unknown: 'Unknown state',
        index_salutation: 'Hello!',
      }
    },
    de: {
      translations: {
        label_actions: 'Aktionen',
        label_show: 'Anzeigen',
        label_back_to_list: 'Zurück zur Übersicht',
        label_create: 'Erstellen',
        label_create_commodity: 'Produkt erstellen',
        label_create_customer: 'Kunden erstellen',
        label_select: 'Auswählen...',
        label_back: 'Zurück',
        label_not_selected: 'Nicht ausgewählt',
        label_edit: 'Editieren',
        label_save: 'Speichern',
        label_discard: 'Änderungen verwerfen',
        label_delete: 'Löschen',
        label_saved: 'Gespeichert',
        label_column: 'Spalte',
        label_demand: 'Anfrage',
        label_demands: 'Anfragen',
        label_commodity: 'Produkt',
        label_commodities: 'Produkte',
        label_customer: 'Kunde',
        label_customers: 'Kunden',
        label_customer_commodities: 'Produkt/Kunde Beziehungen',
        label_customer_commodity: 'Produkt/Kunde Beziehung',
        label_categories: 'Kategorien',
        label_external_match_code: 'Externe Nummer',
        label_external_match_codes: 'Externe Nummern',
        label_external_match_code_select: 'Externe Produktnummer auswählen',
        label_internal_match_code: 'Interne Nummer',
        label_internal_match_code_select: 'Interne Produktnummer auswählen',
        label_filter_last_month: 'Letztes Monat',
        label_filter_last_3_month: 'Letzten drei Monate',
        label_select_customer: 'Kunde auswählen',
        label_demand_quantity: 'Angefragte Menge',
        label_demand_quantity_select: 'Angefragte Menge auswählen',
        label_name: 'Name',
        label_postal_code: 'Postleitzahl',
        label_sales_closing_price: 'Verkauspreis untere Grenze',
        label_sales_distribution_price: 'Verkaufspreis UVP',
        label_sales_fixed_price: 'Fixierter Verkaufspreis',
        label_rematch: 'Rematch',
        label_add_external_matchcode: 'Externe Produktnummer hinzufügen',
        label_year_month_group: 'Monat, Jahr',
        label_demand_emerged: 'Anfrage entstanden',
        label_fetching: 'Laden ...',
        label_error: 'Fehler',
        demand_matched_commodity_code: 'Zugeordnete Produktnummer',
        demand_bulk_create: 'Anfragen gesammelt erstellen',
        demand_bulk_step_1_select_customer: '1. Kunde auswählen',
        demand_bulk_step_2_paste_table: '2. Tabellendaten einfügen',
        demand_bulk_step_3_match_columns: '3. Tabellenkopfzeile zuordnen',
        demand_bulk_status_created: 'Anfrage erstellt',
        demand_bulk_status_created_and_relation: 'Anfrage und Verknüpfung zwischen Kunden und Produkt erstellt',
        demand_bulk_status_customer_not_found: 'Kunde nicht gefunden',
        demand_bulk_status_external_mc_not_found: 'Externe Produktnummer nicht gefunden für Kunden',
        demand_bulk_status_internal_mc_not_found: 'Interne Produktnummer nicht gefunden',
        demand_bulk_status_internal_inconsistent_data: 'Inkonsistenter Datensatz',
        demand_bulk_status_unknown: 'Unbekannter Status',
        index_salutation: 'Guten Tag!',
      }
    }
  },
  fallbackLng: "de",
  debug: true,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ","
  },

  react: {
    wait: true
  }
});

export default i18n;
