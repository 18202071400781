import { httpGET } from '../util/apiInterface';

const initialState = {

  categories: [],

  fetching: false,
  error: null,

};

export const states = {

  FETCH: 'CATEGORY_FETCH',
  ERROR: 'CATEGORY_ERROR',

  LIST_SUCCESS: 'CATEGORY_LIST_SUCCESS',

};

export const actions = {

  list: () => {

    return httpGET('/categories', {

      fetch: states.FETCH,
      success: states.LIST_SUCCESS,
      error: states.ERROR,

    });

  },

};

export const reducer = (state = initialState, action) => {

  switch (action.type) {

    case states.FETCH:
      return {
        ...state,
        fetching: true,
      };

    case states.LIST_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        categories: action.payload.response,
      };


    case states.ERROR:
      return {
        ...state,
        fetching: false,
        error: action.payload.error
      };

    default:
      return state;

  }

};
