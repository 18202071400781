const API_ENDPOINT = 'http://dominikfarmer-001-site1.ftempurl.com/api';

export const fetch = (url, options = {}, { fetch, success, error }, params) => (dispatch) => {

  let fetchOptions = { ...options };

  //fetchOptions.credentials = 'include';

  dispatch({ type: fetch, payload: { url, params } });

  window.fetch(url, fetchOptions)

  .then((response) => {

    if (response.statusText === 'No Content') {

      return response;

    }

    return response.json();

  })

  .then((response) => {

    if (response.status && response.status.code >= 400) {

      dispatch({ type: error, payload: { url, params, error: response.status } });

    } else {

      dispatch({ type: success, payload: { url, params, response }});

    }

  })

  .catch((errorMessage) => {

    dispatch({ type: error, payload: { url, params, error: errorMessage } });

  });

};

export const httpGET = (url, reducerStates, params) => {

  return fetch(`${API_ENDPOINT}${url}`, {}, reducerStates, params);

};

export const httpPOST = (url, body, reducerStates, params) => {

  let options = {

    method: 'POST',
    body: JSON.stringify(body),

    headers: {

      'Content-Type': 'application/json'

    }

  };

  return fetch(`${API_ENDPOINT}${url}`, options, reducerStates, params);

};

export const httpPUT = (url, body, reducerStates, params) => {

  let options = {

    method: 'PUT',
    body: JSON.stringify(body),

    headers: {

      'Content-Type': 'application/json'

    }

  };

  return fetch(`${API_ENDPOINT}${url}`, options, reducerStates, params);

};

export const httpDELETE = (url, reducerStates, params) => {

  let options = {

    method: 'DELETE',

  };

  return fetch(`${API_ENDPOINT}${url}`, options, reducerStates, params);

};
