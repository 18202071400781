import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { withTranslation, Trans } from "react-i18next";
import { compose } from 'redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { matchableValueFormatter, matchableKeyCreator, valueFormatterLongDateTime, dateFilterComparator, dateKeyCreatorMonthYear, dateValueFormatterMonthYear } from '../../../agGridHelpers';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { defaultColDef } from '../../../agGridHelpers';

import { localeText } from '../../../localeAGGrid';

import { actions as DemandActions } from '../../../reducer/demand';

class DemandListView extends Component {

  constructor (props) {
    super(props);

    this.onGridReady = this.onGridReady.bind(this);
    this.onCellDoubleClicked = this.onCellDoubleClicked.bind(this);
    this.rematch = this.rematch.bind(this);
  }

  componentDidMount () {

    this.props.dispatch(DemandActions.list('all_demands'));

  }

  onGridReady (params) {

    this.gridApi = params.api;

  }

  onCellDoubleClicked (eventArgs) {

    this.props.history.push(`demand/${eventArgs.data.id}`);

  }

  rematch () {

    this.props.dispatch(DemandActions.rematch('all_demands'));

  }

  setLastXMonths (months) {

    function lpad (str, len, fill='0') {

      str = str.toString();

      while (str.length < len) {
        str = fill + str;
      }

      return str;

    }

    return () => {
      let now = new Date();

      let monthStart = now.getMonth() + 1;
      let yearStart = now.getYear() + 1900;
      let startString = `${yearStart}-${lpad(monthStart, 2)}-01`;

      let monthEnd = monthStart - months;
      let yearEnd = yearStart;
      if (monthEnd < 1) {
        yearEnd -= 1;
        monthEnd += 12;
      }
      let endString = `${yearEnd}-${lpad(monthEnd, 2)}-01`;

      let emergedFilter = this.gridApi.getFilterInstance('demandEmerged');
      emergedFilter.setModel({
        condition1: {
          type: "lessThan",
          dateFrom: startString,
          dateTo: null
        },
        condition2: {
          type: "greaterThan",
          dateFrom: endString,
          dateTo: null
        },
        operator: "AND"
      });
      this.gridApi.onFilterChanged();
    };

  }

  getRowStyle ({ data }) {

    const rematchData = this.props.demandStore.rematches['all_demands'];

    let lastDate = null;

    if (this.props.location.state) {

      lastDate = this.props.location.state.lastDate;

    }

    if (data) {

      if (lastDate && new Date(data.demandEmerged) - new Date(lastDate) === 0) {

        return { backgroundColor: '#c1ced6' };

      }

      if (rematchData) {

        let newMatchIds = new Set(rematchData.newMatches.map(d => d.id));
        let notMatchedIds = new Set(rematchData.notMatched.map(d => d.id));

        if(newMatchIds.has(data.id)) {

          return { backgroundColor: 'hsl(130, 20%, 80%)' };

        }

        if (notMatchedIds.has(data.id)) {

          return { backgroundColor: 'hsl(0, 20%, 80%)' };

        }

      }


    }

  }

  render () {

    const demandData = { ...this.props.demandStore.collections['all_demands'] };

    const columnDefinitions = [
      {
        headerName: this.props.i18n.t('label_year_month_group'),
        field: 'year_month_group',
        sortable: true,
        keyCreator: dateKeyCreatorMonthYear,
        valueFormatter: dateValueFormatterMonthYear,
        enableRowGroup: true,
        hide: true,
      },
      {
        headerName: this.props.i18n.t('label_demand_emerged'),
        field: 'demandEmerged',
        sortable: true,
        sort: "desc",
        valueFormatter: valueFormatterLongDateTime,
        filter: "agDateColumnFilter",
        filterParams: { comparator: dateFilterComparator },
      },
      {
        headerName: this.props.i18n.t('label_demand_quantity'),
        field: 'demandedQuantity',
        sortable: true,
        filter: 'agNumberColumnFilter',
        allowedAggFuncs: ['sum','min','max'],
        enableValue: true,
        editable: true,
      },
      {
        headerName: this.props.i18n.t('label_commodity'),
        field: 'commodity',
        cellRenderer: matchableValueFormatter,
        keyCreator: matchableKeyCreator,
        filter: true,
        enableRowGroup: true,
      },
      {
        headerName: this.props.i18n.t('label_customer'),
        field: 'customer',
        valueFormatter: matchableValueFormatter,
        keyCreator: matchableKeyCreator,
        enableRowGroup: true,
        filter: true,
      },
      {
        headerName: this.props.i18n.t('demand_matched_commodity_code'),
        field: 'matchedCommodityCode',
        sortable: true,
        filter: true
      },
    ];

    if (!demandData.data) {
      return null;
    }

    if (this.gridApi) {
      this.gridApi.redrawRows();
    }

    return (
      <>
      <div className="pb-space pb-flex">
        <Link className="pb-button pb-button-inline" to="/demand/new">
          <FontAwesomeIcon className="pb-inline-icon" icon="plus" />
          <Trans i18nKey="label_create" />
        </Link>
        <Link className="pb-button pb-button-inline" to="/demand/bulk">
          <FontAwesomeIcon className="pb-inline-icon" icon="angle-double-down" />
          <Trans i18nKey="demand_bulk_create" />
        </Link>
        <button className="pb-button" onClick={ this.rematch }>
          <FontAwesomeIcon className="pb-inline-icon" icon="sync-alt" />
          Rematch
        </button>
      </div>
      <div className="pb-space pb-flex">
        <button className="pb-button pb-button-inline" onClick={ this.setLastXMonths(1) }>
          <FontAwesomeIcon className="pb-inline-icon" icon="filter" />
          <Trans i18nKey="label_filter_last_month" />
        </button>
        <button className="pb-button pb-button-inline" onClick={ this.setLastXMonths(3) }>
          <FontAwesomeIcon className="pb-inline-icon" icon="filter" />
          <Trans i18nKey="label_filter_last_3_month" />
        </button>
      </div>

      <div className="pb-ag-grid ag-theme-balham">
        <AgGridReact
          sideBar={true}
          defaultColDef={ defaultColDef }
          columnDefs={ columnDefinitions }
          rowData={ demandData.data.map((d) => ({ ...d, year_month_group: d.demandEmerged })) }
          getRowStyle={ this.getRowStyle.bind(this) }
          onCellDoubleClicked={ this.onCellDoubleClicked }
          rowSelection="multiple"
          onGridReady={ this.onGridReady }
          localeText={ localeText }
        />
      </div>
      </>
    );

  }

}

const enhance = compose(
  withRouter,
  withTranslation(),
  connect((store) => ({
    demandStore: store.DemandReducer
  }))
);

export default enhance(DemandListView);
