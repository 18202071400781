export default function renderDate (dateString ) {

  const date = new Date(dateString);

  if (isFinite(date)) {

    return date
      .toLocaleString('de-AT', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });

  }

  return '-';

}
