import React, { Component } from 'react';

import './style.css';

class FormInput extends Component {

  render () {

    return (
      <select { ...this.props } className="pb-form-select" />
    );

  }

}

export default FormInput;
