import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { withTranslation, Trans } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import Form from '../../section/form';
import FormInput from '../../section/form-input';

import { MatchableType } from '../../../matchableType';

import { actions as MatchableActions } from '../../../reducer/matchable';

class CustomerDetailsView extends Component {

  onChange (eventArgs) {

    const { matchableId } = this.props.match.params;

    this.props.dispatch(
      MatchableActions.set(
        matchableId,
        eventArgs.target.name,
        eventArgs.target.value,
      )
    );

  }

  onDiscard () {

    const { matchableId } = this.props.match.params;

    this.props.dispatch(
      MatchableActions.get(matchableId, MatchableType.CUSTOMER)
    );

  }

  onDelete () {

    const { matchableId } = this.props.match.params;

    this.props.dispatch(
      MatchableActions.delete(matchableId, MatchableType.CUSTOMER)
    );

  }

  onSubmit (eventArgs) {
    eventArgs.preventDefault();

    const { matchableId } = this.props.match.params;
    const customer = this.props.matchableStore.singles[matchableId].data;

    if (matchableId === 'new') {

      this.props.dispatch(
        MatchableActions.createCustomer(matchableId, customer)
      );

    } else {

      this.props.dispatch(
        MatchableActions.persistCustomer(matchableId, customer)
      );

    }

  }

  onCustomerCommodityCellDoubleClicked (eventArgs) {

    const { matchableId } = this.props.match.params;

    if (eventArgs.data && eventArgs.data.id) {

      this.props.history.push(`/customerCommodity/${eventArgs.data.id}?customerId=${matchableId}`);

    }

  }

  componentDidMount () {

    const { matchableId } = this.props.match.params;

    if (matchableId === 'new') {

      this.props.dispatch(
        MatchableActions.new(matchableId, MatchableType.CUSTOMER)
      );

    } else {

      this.props.dispatch(
        MatchableActions.get(matchableId, MatchableType.CUSTOMER)
      );

    }

  }

  renderDetails (matchableData) {

    const customer = matchableData.data;

    const columnDefinitions = [
      {
        headerName: this.props.i18n.t('label_commodity'),
        field: 'commodity',
        valueFormatter: (params) => {
          if (params && params.value) {
            if (typeof params.value === 'string')
              return params.value;
            return `${params.value.matchCode} (${params.value.name})`;
          }
          return '';
        },
        keyCreator: (params) => {
          if (params && params.value) {
            return params.value.name;
          }
          return 'Not Matched';
        },
        filter: true,
        rowGroup: true,
        hide: true,
      },
      {
        headerName: this.props.i18n.t('label_external_match_code'),
        field: 'extrnalMatchCode',
        sortable: true,
        filter: true
      },
      {
        headerName: this.props.i18n.t('label_sales_fixed_price'),
        field: 'salesFixedPrice',
        sortable: true,
        filter: true,
      },
    ];

    return (
      <>
        <Form onSubmit={ this.onSubmit.bind(this) }>

          <h1 className="pb-space">
          { matchableData.created === true ?
            (
              <>{ this.props.i18n.t('label_create') }: { this.props.i18n.t('label_customer') }</>
            ):
            (
              <>
                { this.props.i18n.t('label_edit') }: { this.props.i18n.t('label_customer') }
                { matchableData.altered === true ? (
                  <FontAwesomeIcon icon="edit" />
                ) : (null) }
              </>
            )
          } </h1>

          <div className="pb-space">
            <Trans i18nKey="label_internal_match_code" />
            <FormInput
              type="text"
              name="matchCode"
              value={ customer.matchCode }
              onChange={ this.onChange.bind(this) }
            />
          </div>

          <div className="pb-space">
            <Trans i18nKey="label_name" />
            <FormInput
              type="text"
              name="name"
              value={ customer.name }
              onChange={ this.onChange.bind(this) }
            />
          </div>

          <div className="pb-space">
            <Trans i18nKey="label_postal_code" />
            <FormInput
              type="text"
              name="postalCode"
              value={ customer.postalCode }
              onChange={ this.onChange.bind(this) }
            />
          </div>

          <div>{ matchableData.created === true ?
            (
              <>
                <FormInput
                  type="submit"
                  value={ this.props.i18n.t('label_create') }
                  pbStyle="success"
                />
              </>
            ):
            (
              <>
                { matchableData.altered === true ? (
                  <>
                    <FormInput
                      type="submit"
                      value={ this.props.i18n.t('label_save') }
                      pbStyle="success"
                    />
                    <FormInput
                      type="button"
                      value={ this.props.i18n.t('label_discard') }
                      onClick={ this.onDiscard.bind(this) }
                      pbStyle="warning"
                    />
                  </>
                ) : (null) }
                <FormInput
                  type="button"
                  value={ this.props.i18n.t('label_delete') }
                  onClick={ this.onDelete.bind(this) }
                  pbStyle="danger"
                />
              </>
            )
          }</div>
          <FormInput
            type="button"
            value={ this.props.i18n.t('label_back') }
            onClick={ this.props.history.goBack }
          />
        </Form>

        { !matchableData.created ?
          (
            <div className="pb-group">
              <h2 className="pb-space">
                <Trans i18nKey="label_external_match_codes" />
              </h2>

              <div className="pb-space">
                <Link className="pb-button" to={ `/customerCommodity/new?customerId=${customer.id}` }>{ this.props.i18n.t('label_create') }</Link>
              </div>

              <div className="ag-theme-balham">
                <AgGridReact
                  columnDefs={ columnDefinitions }
                  rowData={ customer.customerCommodities }
                  onCellDoubleClicked= { this.onCustomerCommodityCellDoubleClicked.bind(this) }
                />
              </div>
            </div>
          ) : (null)
        }
      </>
    );

  }

  renderFetching () {

    return (
      <div className="pb-group">
        <h1>
          <Trans i18nKey="label_fetching" />
        </h1>
      </div>
    );

  }

  renderError () {

    return (
      <div className="pb-group">
        <h1>
          <Trans i18nKey="label_error" />
        </h1>
      </div>
    );

  }

  render () {

    const { matchableId } = this.props.match.params;
    const matchable = { ...this.props.matchableStore.singles[matchableId] };

    if (!matchable || matchable.fetching) {

      return this.renderFetching();

    }

    if (matchable.deleted) {

      this.props.history.push('/customer');

    }

    if (matchable.error === null) {

      return this.renderDetails(matchable);

    }

    return this.renderError();

  }


}

const enhance = compose(
  withTranslation(),
  withRouter,
  connect((store) => ({

    matchableStore: store.MatchableReducer,

  }))
);

export default enhance(CustomerDetailsView);
