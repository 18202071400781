import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from "react-i18next";

import i18n from "./i18n";

import { LicenseManager } from "ag-grid-enterprise";

import './fontawesome';
import './index.css';

import App from './App';


// Number.prototype.pad = function(size) {
//     var s = String(this);
//     while (s.length < (size || 2)) {s = "0" + s;}
//     return s;
// };

LicenseManager.setLicenseKey('Evaluation_License-_Not_For_Production_Valid_Until_18_April_2019__MTU1NTU0MjAwMDAwMA==8fd2c70c6df61f5ac31e95fee7a8c13a');
ReactDOM.render(<I18nextProvider i18n={i18n}><App /></I18nextProvider>, document.getElementById('root'));
