
export const MatchableType = {

  COMMODITY: 'MATCHABLE_TYPE_COMMODITY',
  CUSTOMER: 'MATCHABLE_TYPE_CUSTOMER',

};

export const MatchableTypeURLs = new Map([
  [ MatchableType.COMMODITY, '/commodities' ],
  [ MatchableType.CUSTOMER, '/customers' ],
]);

export function matchableTypeToURL (matchableType) {

  const url = MatchableTypeURLs.get(matchableType);

  if (url) {

    return url;

  }

  throw new Error(`Invalid MatchableType "${matchableType}"`);

}

export const MatchableTypeLocalPaths = new Map([
  [ MatchableType.COMMODITY, '/commodity' ],
  [ MatchableType.CUSTOMER, '/customer' ],
]);

export function matchableTypeToLocalPaths (matchableType) {

  const url = MatchableTypeLocalPaths.get(matchableType);

  if (url) {

    return url;

  }

  throw new Error(`Invalid MatchableType "${matchableType}"`);

}
