import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

import MenuMain from '../menu-main';

import './style.css';

class ScreenDefault extends Component {

  constructor (props) {
    super(props);

    this.changeLanguage = this.changeLanguage.bind(this);

  }

  changeLanguage (language) {

    return () => {

      if (this.props.changeLanguage) {
        this.props.changeLanguage(language);
      }

    };

  }

  render () {

    return (
      <div className="pb-screen-default">

        <header className="pb-screen-default__header">
          <h1><Link className="pb-screen-default__logo" to="/">Printberry</Link></h1>

          <div>
            <button className="pb-button pb-button-inline" onClick={ this.changeLanguage('de') }>DE</button>
            <button className="pb-button pb-button-inline" onClick={ this.changeLanguage('en') }>EN</button>
          </div>
        </header>

        <div className="pb-screen-default__container">
          <aside className="pb-screen-default__aside">

            <MenuMain />

          </aside>

          <main className="pb-screen-default__main">

            { this.props.children }

          </main>
        </div>

      </div>
    );

  }

}

export default withTranslation()(ScreenDefault);
