import { combineReducers } from 'redux';

import { reducer as FormReducer } from './form';
import { reducer as MatchableReducer } from './matchable';
import { reducer as DemandReducer } from './demand';
// import { reducer as DemandReducerOld } from './demand-old';
import { reducer as DemandBulkReducer } from './demand-bulk';
// import { reducer as CategoryReducer } from './category';
// import { reducer as CommodityReducer } from './commodity';
// import { reducer as CustomerReducer } from './customer';
import { reducer as CustomerCommodityReducer } from './customer-commodity';

export const reducers = combineReducers({

  FormReducer,
  MatchableReducer,
  DemandReducer,
  // DemandReducerOld,
  DemandBulkReducer,
  // CategoryReducer,
  // CommodityReducer,
  // CustomerReducer,
  CustomerCommodityReducer,

});
