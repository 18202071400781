import React, { Component } from 'react';

import './style.css';

class FormInput extends Component {

  getClassName () {

    let classNames = [ 'pb-form-input' ];

    switch (this.props.pbStyle) {

      case 'success':
        classNames.push('pb-form-input-success');
        break;

      case 'warning':
        classNames.push('pb-form-input-warning');
        break;

      case 'danger':
        classNames.push('pb-form-input-danger');
        break;

      default:

    }

    return classNames.join(' ');

  }

  render () {

    if (this.props.type === 'submit' || this.props.type === 'button') {

      return (
        <button { ...this.props } className={ this.getClassName() }>
          <>
          { this.props.value }
          { this.props.children }
          </>
        </button>
      );


    }
    else {

      return (
        <input { ...this.props } className={ this.getClassName() } />
      );

    }

  }

}

export default FormInput;
