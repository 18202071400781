export const localeText = {
 page: "Seite",
 more: "Mehr",
 to: "bis",
 of: "aus",
 next: "nächste",
 last: "vorherige",
 first: "Erste",
 previous: "daPreviousen",
 loadingOoo: "Laden ...",
 selectAll: "Alle Auswählen",
 searchOoo: "Suche...",
 blanks: "Leer",
 filterOoo: "Filter...",
 applyFilter: "Filter anwenden...",
 equals: "Gleich",
 notEqual: "Ungleich",
 lessThan: "Kleiner als",
 greaterThan: "Größer als",
 lessThanOrEqual: "Kleiner oder gleich als",
 greaterThanOrEqual: "Größer oder gleich als",
 inRange: "Im Bereich",
 contains: "Enthält",
 notContains: "Enthält nicht",
 startsWith: "Beginnt mit",
 endsWith: "Endet mit",
 group: "Gruppe",
 columns: "Spalten",
 filters: "Filter",
 rowGroupColumns: "Pivotspalten",
 rowGroupColumnsEmptyMessage: "Werte zum Gruppieren hier her ziehen",
 valueColumns: "Wertespalten",
 pivotMode: "Pivot Modus",
 groups: "Gruppen",
 values: "Werte",
 pivots: "Pivots",
 valueColumnsEmptyMessage: "Werte zum Berechnen hier her ziehen",
 pivotColumnsEmptyMessage: "Werte zum Gruppieren hier her ziehen",
 toolPanelButton: "Einstellungen",
 noRowsToShow: "Keine Zeilen vorhanden",
 pinColumn: "Spalte anheften",
 valueAggregation: "Berechnungen",
 autosizeThiscolumn: "Größe automatisch anpassen",
 autosizeAllColumns: "Größe aller Spalten automatisch anpassen",
 groupBy: "Gruppieren nach",
 ungroupBy: "Aufheben: Gruppieren nach",
 resetColumns: "Spalten zurücksetzen",
 expandAll: "Alle Spalten anzeigen",
 collapseAll: "Alle Spalten verbergen",
 toolPanel: "Einstellungen",
 export: "Exportieren",
 csvExport: "Als CSV-Datei exportieren",
 excelExport: "Als Excel-Datei exportieren",
 pinLeft: "Links anheften",
 pinRight: "Rechts anheften",
 noPin: "Nicht anheften",
 sum: "Summe",
 min: "Minimalwert",
 max: "Maximalwert",
 none: "Nichts",
 count: "Anzahl",
 average: "Durchschnitt",
 copy: "Kopieren",
 copyWithHeaders: "Kopieren mit Überschrift",
 ctrlC: "CTRL C",
 paste: "Einfügen",
 ctrlV: "CTRL V"
};
