
import { httpPOST } from '../util/apiInterface';

const initialState = {

  fetching: false,
  error: null,

  latestDemandEmerged: null,

};

export const states = {

  BULK_CREATE_FETCH: 'DEMAND_BULK_BULK_CREATE_FETCH',
  BULK_CREATE_SUCCESS: 'DEMAND_BULK_BULK_CREATE_SUCCESS',
  BULK_CREATE_ERROR: 'DEMAND_BULK_BULK_CREATE_ERROR',

  RESET: 'DEMAND_BULK_RESET',

};

export const actions = {

  bulkCreate: (demands) => {

    return httpPOST(`/commodityDemands/bulk`, demands, {

      fetch: states.BULK_CREATE_FETCH,
      success: states.BULK_CREATE_SUCCESS,
      error: states.BULK_CREATE_ERROR,

    });

  },

  reset: () => ({

    type: states.RESET,

  }),

};

export const reducer = (state = initialState, action) => {

  switch (action.type) {

    case states.BULK_CREATE_FETCH:
      return {
        ...state,
        fetching: true,
      };


    case states.BULK_CREATE_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        latestDemandEmerged: action.payload.response.demandEmerged,
      };

    case states.BULK_CREATE_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.payload.error
      };

    case states.RESET:
      return {
        ...initialState
      };

    default:
      return state;

  }

};
