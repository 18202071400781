
import { httpGET, httpPOST, httpPUT, httpDELETE } from '../util/apiInterface';
import { matchableTypeToURL, MatchableType } from '../matchableType';

const initialState = {

  fetchingCount: 0,

  collections: {},
  singles: {},

  errors: [],

};

export const states = {

  FETCH: 'MATCHABLE_FETCH',
  ERROR: 'MATCHABLE_ERROR',

  GET_SUCCESS: 'MATCHABLE_GET_SUCCESS',

  SET: 'MATCHABLE_SET',

  NEW: 'MATCHABLE_NEW',

  CREATE_SUCCESS: 'MATCHABLE_CREATE_SUCCESS',

  PERSIST_SUCCESS: 'MATCHABLE_PERSIST_SUCCESS',

  DELETE_SUCCESS: 'MATCHABLE_DELETE_SUCCESS',

  LIST_FETCH: 'MATCHABLE_LIST_FETCH',
  LIST_SUCCESS: 'MATCHABLE_LIST_SUCCESS',
  LIST_ERROR: 'MATCHABLE_LIST_ERROR',

};

export const actions = {

  get: (id, matchableType) => {

    const url = matchableTypeToURL(matchableType);

    return httpGET(`${url}/${id}`, {

      fetch: states.FETCH,
      success: states.GET_SUCCESS,
      error: states.ERROR,

    }, { id });

  },

  set: (id, name, value) => ({

    type: states.SET,
    payload: {
      id,
      name,
      value,
    }

  }),

  new: (id) => ({

    type: states.NEW,
    payload: {
      id,
    }

  }),

  createCommodity: (id, commodity) => {

    const url = matchableTypeToURL(MatchableType.COMMODITY);

    const body = {

      matchCode: commodity.matchCode,
      name: commodity.name,

      salesClosingPrice: commodity.salesClosingPrice,
      salesDistributionPrice: commodity.salesDistributionPrice,

    };

    return httpPOST(url, body, {

      fetch: states.FETCH,
      success: states.CREATE_SUCCESS,
      error: states.ERROR,

    }, { id });

  },

  createCustomer: (id, customer) => {

    const url = matchableTypeToURL(MatchableType.CUSTOMER);

    const body = {

      matchCode: customer.matchCode,
      name: customer.name,

      postalCode: customer.postalCode,

    };

    return httpPOST(url, body, {

      fetch: states.FETCH,
      success: states.CREATE_SUCCESS,
      error: states.ERROR,

    }, { id });

  },

  persistCommodity: (id, commodity) => {

    const url = matchableTypeToURL(MatchableType.COMMODITY);

    const body = {

      id,

      matchCode: commodity.matchCode,
      name: commodity.name,

      salesClosingPrice: commodity.salesClosingPrice,
      salesDistributionPrice: commodity.salesDistributionPrice,

    };

    return httpPUT(`${url}/${id}`, body, {

      fetch: states.FETCH,
      success: states.PERSIST_SUCCESS,
      error: states.ERROR,

    }, { id });

  },

  persistCustomer: (id, customer) => {

    const url = matchableTypeToURL(MatchableType.CUSTOMER);

    const body = {

      id,

      matchCode: customer.matchCode,
      name: customer.name,

      postalCode: customer.postalCode,

    };

    return httpPUT(`${url}/${id}`, body, {

      fetch: states.FETCH,
      success: states.PERSIST_SUCCESS,
      error: states.ERROR,

    }, { id });

  },

  delete: (id, matchableType) => {

    const url = matchableTypeToURL(matchableType);

    return httpDELETE(`${url}/${id}`, {

      fetch: states.FETCH,
      success: states.DELETE_SUCCESS,
      error: states.ERROR,

    }, { id });

  },

  list: (name, matchableType) => {

    const url = matchableTypeToURL(matchableType);

    return httpGET(url, {

      fetch: states.LIST_FETCH,
      success: states.LIST_SUCCESS,
      error: states.LIST_ERROR,

    }, { name });

  },

};

export const reducer = (state = initialState, action) => {

  switch (action.type) {

    case states.FETCH:
      return {

        ...state,
        fetchingCount: state.fetchingCount + 1,

        singles: {

          ...state.singles,

          [action.payload.params.id]: {

            ...state.singles[action.payload.params.id],
            fetching: true,

          }

        }

      };

    case states.ERROR:
      return {

        ...state,
        fetchingCount: state.fetchingCount - 1,
        errors: [ ...state.errors, action.payload.error ],

        singles: {

          ...state.singles,

          [action.payload.params.id]: {

            ...state.singles[action.payload.params.id],
            fetching: false,
            error: action.payload.error,

          }

        }

      };

    case states.GET_SUCCESS:
      return {

        ...state,
        fetchingCount: state.fetchingCount - 1,

        singles: {

          ...state.singles,

          [action.payload.params.id]: {

            ...state.singles[action.payload.params.id],
            fetching: false,
            altered: false,
            data: action.payload.response,
            error: null,

          }

        }

      };

    case states.SET:
      return {

        ...state,

        singles: {

          ...state.singles,

          [action.payload.id]: {

            ...state.singles[action.payload.id],
            fetching: false,
            altered: true,
            data: {

              ...state.singles[action.payload.id].data,
              [action.payload.name]: action.payload.value,

            },

          }

        }

      };

    case states.NEW:
      return {

        ...state,

        singles: {

          ...state.singles,

          [action.payload.id]: {

            ...state.singles[action.payload.id],
            fetching: false,
            error: null,
            created: true,
            data: { },

          }

        }

      };

    case states.CREATE_SUCCESS:
      return {

        ...state,
        fetchingCount: state.fetchingCount - 1,

        singles: {

          ...state.singles,

          [action.payload.params.id]: {

            ...state.singles[action.payload.params.id],
            fetching: false,
            created: false,
            altered: false,
            error: null,

          }

        }

      };

    case states.PERSIST_SUCCESS:
      return {

        ...state,
        fetchingCount: state.fetchingCount - 1,

        singles: {

          ...state.singles,

          [action.payload.params.id]: {

            ...state.singles[action.payload.params.id],
            fetching: false,
            altered: false,
            error: null,

          }

        }

      };

    case states.DELETE_SUCCESS:
      return {

        ...state,
        fetchingCount: state.fetchingCount - 1,

        singles: {

          ...state.singles,

          [action.payload.params.id]: {

            ...state.singles[action.payload.params.id],
            fetching: false,
            altered: false,
            deleted: true,
            error: null,

          }

        }

      };

    case states.LIST_FETCH:
      return {

        ...state,
        fetchingCount: state.fetchingCount + 1,

        collections: {

          ...state.collections,

          [action.payload.params.name]: {

            ...state.collections[action.payload.params.name],
            fetching: true,

          }

        }

      };

    case states.LIST_SUCCESS:
      return {

        ...state,
        fetchingCount: state.fetchingCount - 1,

        collections: {

          ...state.collections,

          [action.payload.params.name]: {

            ...state.collections[action.payload.params.name],
            fetching: false,
            data: action.payload.response,
            error: null,

          }

        }

      };

    case states.LIST_ERROR:
      return {

        ...state,
        fetchingCount: state.fetchingCount - 1,
        errors: [ ...state.errors, action.payload.error ],

        collections: {

          ...state.collections,

          [action.payload.params.name]: {

            ...state.collections[action.payload.params.name],
            fetching: false,
            error: action.payload.error,

          }

        }

      };

    default:
      return state;

  }

};
