import React, { Component } from 'react';
import { Trans } from "react-i18next";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class IndexView extends Component {

  render () {

    return (
      <div className="pb-group">
        <h1 className="pb-space">
          <Trans i18nKey="index_salutation">Hello!</Trans>
        </h1>
        <Link style={({ width: '20rem' })} className="pb-space pb-button" to="/demand/bulk">
          <FontAwesomeIcon icon="arrow-alt-circle-right" /> <Trans i18nKey="demand_bulk_create" />
        </Link>
        <Link style={({ width: '20rem' })} className="pb-space pb-button" to="/">
          <FontAwesomeIcon icon="arrow-alt-circle-right" /> <Trans i18nKey="label_create_commodity" />
        </Link>
        <Link style={({ width: '20rem', display: 'block' })} className="pb-button" to="/">
          <FontAwesomeIcon icon="arrow-alt-circle-right" /> <Trans i18nKey="label_create_customer" />
        </Link>
      </div>
    );

  }

}

export default IndexView;
