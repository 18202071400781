import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { withTranslation, Trans } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { MatchableType } from '../../../matchableType';

import Form from '../../section/form';
import FormInput from '../../section/form-input';
import MatchablePicker from '../../section/matchable-picker';


import { actions as CustomerCommodityActions } from '../../../reducer/customer-commodity';

class CustomerCommodityDetailsView extends Component {

  onChange (eventArgs) {

    const { customerCommodityId } = this.props.match.params;

    this.props.dispatch(
      CustomerCommodityActions.set(
        customerCommodityId,
        eventArgs.target.name,
        eventArgs.target.value,
      )
    );

  }

  onCustomerSelected (eventArgs) {

    const { customerCommodityId } = this.props.match.params;

    this.props.dispatch(
      CustomerCommodityActions.set(
        customerCommodityId,
        'customerId',
        eventArgs.matchableSelectedId,
      )
    );

  }

  onCommoditySelected (eventArgs) {

    const { customerCommodityId } = this.props.match.params;

    this.props.dispatch(
      CustomerCommodityActions.set(
        customerCommodityId,
        'commodityId',
        eventArgs.matchableSelectedId,
      )
    );

  }

  onDiscard () {

    const { customerCommodityId } = this.props.match.params;

    this.props.dispatch(
      CustomerCommodityActions.get(customerCommodityId)
    );

  }

  onDelete () {

    const { customerCommodityId } = this.props.match.params;

    this.props.dispatch(
      CustomerCommodityActions.delete(customerCommodityId)
    );

  }

  onSubmit (eventArgs) {
    eventArgs.preventDefault();

    const { customerCommodityId } = this.props.match.params;
    const customerCommodity = this.props.customerCommodityStore.singles[customerCommodityId].data;

    if (customerCommodityId === 'new') {

      this.props.dispatch(
        CustomerCommodityActions.create(customerCommodityId, customerCommodity)
      );

    } else {

      this.props.dispatch(
        CustomerCommodityActions.persist(customerCommodityId, customerCommodity)
      );

    }

  }

  componentDidMount () {

    const { customerCommodityId } = this.props.match.params;
    const { commodityId, customerId } = queryString.parse(this.props.location.search);

    if (customerCommodityId === 'new') {

      this.props.dispatch(
        CustomerCommodityActions.new(customerCommodityId, { commodityId, customerId })
      );

    } else {

      this.props.dispatch(
        CustomerCommodityActions.get(customerCommodityId)
      );

    }

  }

  renderDetails (customerCommodityData) {

    const customerCommodity = customerCommodityData.data;

    return (
      <Form onSubmit={ this.onSubmit.bind(this) }>

        <h1 className="pb-space">
        { customerCommodityData.created === true ?
          (
            <>{ this.props.i18n.t('label_create') }: { this.props.i18n.t('label_customer_commodity') }</>
          ):
          (
            <>
              { this.props.i18n.t('label_edit') }: { this.props.i18n.t('label_customer_commodity') }
              { customerCommodityData.altered === true ? (
                <FontAwesomeIcon icon="edit" />
              ) : (null) }
            </>
          )
        } </h1>

        <div className="pb-space">
          <Trans i18nKey="label_customer" />
          <MatchablePicker
            name="all_customers"
            matchableType={ MatchableType.CUSTOMER }
            matchableSelectedId= { customerCommodity.customerId }
            onMatchableChanged={ this.onCustomerSelected.bind(this) }
          />
        </div>

        <div className="pb-space">
          <Trans i18nKey="label_commodity" />
          <MatchablePicker
            name="all_commodities"
            matchableType={ MatchableType.COMMODITY }
            matchableSelectedId= { customerCommodity.commodityId }
            onMatchableChanged={ this.onCommoditySelected.bind(this) }
          />
        </div>

        <div className="pb-space">
          <Trans i18nKey="label_sales_fixed_price" />
          <FormInput
            type="text"
            name="salesFixedPrice"
            value={ customerCommodity.salesFixedPrice }
            onChange={ this.onChange.bind(this) }
          />
        </div>

        <div className="pb-space">
          <Trans i18nKey="label_external_match_code" />
          <FormInput
            type="text"
            name="extrnalMatchCode"
            value={ customerCommodity.extrnalMatchCode }
            onChange={ this.onChange.bind(this) }
          />
        </div>

        <div>{ customerCommodityData.created === true ?
          (
            <>
              <FormInput
                type="submit"
                value={ this.props.i18n.t('label_create') }
                pbStyle="success"
              />
            </>
          ):
          (
            <>
              { customerCommodityData.altered === true ? (
                <>
                  <FormInput
                    type="submit"
                    value={ this.props.i18n.t('label_save') }
                    pbStyle="success"
                  />
                  <FormInput
                    type="button"
                    value={ this.props.i18n.t('label_discard') }
                    onClick={ this.onDiscard.bind(this) }
                    pbStyle="warning"
                  />
                </>
              ) : (null) }
              <FormInput
                type="button"
                value={ this.props.i18n.t('label_delete') }
                onClick={ this.onDelete.bind(this) }
                pbStyle="danger"
              />
            </>
          )
        }</div>
        <FormInput
          type="button"
          value={ this.props.i18n.t('label_back') }
          onClick={ this.props.history.goBack }
        />
      </Form>
    );

  }

  renderFetching () {

    return (
      <div className="pb-group">
        <h1>
          <Trans i18nKey="label_fetching" />
        </h1>
      </div>
    );

  }

  renderError () {

    return (
      <div className="pb-group">
        <h1>
          <Trans i18nKey="label_error" />
        </h1>
      </div>
    );

  }

  render () {

    const { customerCommodityId } = this.props.match.params;
    const matchable = { ...this.props.customerCommodityStore.singles[customerCommodityId] };

    if (!matchable || matchable.fetching) {

      return this.renderFetching();

    }

    if (matchable.deleted) {

      const { commodityId, customerId } = queryString.parse(this.props.location.search);

      if (commodityId) {

        this.props.history.push(`/commodity/${commodityId}`);
        return null;

      }

      if (customerId) {

        this.props.history.push(`/customer/${customerId}`);
        return null;

      }

      this.props.history.push('/');
      return null;

    }

    if (matchable.error === null) {

      return this.renderDetails(matchable);

    }

    return this.renderError();

  }


}

const enhance = compose(
  withTranslation(),
  withRouter,
  connect((store) => ({

    customerCommodityStore: store.CustomerCommodityReducer,

  }))
);

export default enhance(CustomerCommodityDetailsView);
