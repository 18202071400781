import React, { Component } from 'react';

class CellCurrency extends Component {

  render () {

    return (
      <span className="pb--number">{ typeof this.props.value === 'number' ? `€ ${this.props.value.toFixed(2)}` : '-' }</span>
    );

  }

}

CellCurrency.options = Symbol();

export default CellCurrency;
