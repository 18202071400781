const initialState = {

  forms: {},

};

export const states = {

  SET: 'FORM_SET',
  RESET: 'FORM_RESET',

};

export const actions = {

  set: (formName, formKey, value) => ({

    type: states.SET,
    payload: {

      formName,
      formKey,
      value

    }

  }),

  reset: (formName) => ({

    type: states.RESET,
    payload: {

      formName,

    }

  }),

};

export const reducer = (state = initialState, action) => {

  switch (action.type) {

    case states.SET:
      return {

        ...state,
        forms: {

          ...state.forms,
          [action.payload.formName]: {

            ...state.forms[action.payload.formName],
            [action.payload.formKey]: action.payload.value,

          }

        }

      };

    case states.RESET:
      return {

        ...state,
        forms: {

          ...state.forms,
          [action.payload.formName]: {}

        }

      };
    default:
      return state;

  }

};
