import React, { Component } from 'react';

import './style.css';

class Form extends Component {

  render () {

    return (
      <form { ...this.props } className="pb-form" />
    );

  }

}

export default Form;
