import { httpGET, httpPOST, httpPUT, httpDELETE } from '../util/apiInterface';

const initialState = {

  fetchingCount: 0,

  collections: {},
  singles: {},
  rematches: {},

  errors: [],

};

export const states = {

  FETCH: 'DEMAND_FETCH',
  ERROR: 'DEMAND_ERROR',

  GET_SUCCESS: 'DEMAND_GET_SUCCESS',

  SET: 'DEMAND_SET',

  NEW: 'DEMAND_NEW',

  CREATE_SUCCESS: 'DEMAND_CREATE_SUCCESS',

  PERSIST_SUCCESS: 'DEMAND_PERSIST_SUCCESS',

  DELETE_SUCCESS: 'DEMAND_DELETE_SUCCESS',

  REMATCH_SUCCESS: 'DEMAND_REMATCH_SUCCESS',

  LIST_FETCH: 'MATCHABLE_LIST_FETCH',
  LIST_SUCCESS: 'MATCHABLE_LIST_SUCCESS',
  LIST_ERROR: 'MATCHABLE_LIST_ERROR',

};

export const actions = {

  get: (id) => {

    return httpGET(`/commodityDemands/${id}`, {

      fetch: states.FETCH,
      success: states.GET_SUCCESS,
      error: states.ERROR,

    }, { id });

  },

  set: (id, name, value) => ({

    type: states.SET,
    payload: {
      id,
      name,
      value,
    }

  }),

  new: (id) => ({

    type: states.NEW,
    payload: {
      id,
    }

  }),

  create: (id, demand) => {

    const body = {

      demandEmerged: demand.demandEmerged,
      demandedQuantity: demand.demandedQuantity,

      customerId: demand.customerId,
      commodityId: demand.commodityId,

      matchedCommodityCode: demand.matchedCommodityCode,

    };

    return httpPOST(`/commodityDemands`, body, {

      fetch: states.FETCH,
      success: states.CREATE_SUCCESS,
      error: states.ERROR,

    }, { id });

  },

  persist: (id, demand) => {

    const body = {

      id,

      demandEmerged: demand.demandEmerged,
      demandedQuantity: demand.demandedQuantity,

      customerId: demand.customerId,
      commodityId: demand.commodityId,

      matchedCommodityCode: demand.matchedCommodityCode,

    };

    return httpPUT(`/commodityDemands/${id}`, body, {

      fetch: states.FETCH,
      success: states.PERSIST_SUCCESS,
      error: states.ERROR,

    }, { id });

  },

  delete: (id) => {

    return httpDELETE(`/commodityDemands/${id}`, {

      fetch: states.FETCH,
      success: states.DELETE_SUCCESS,
      error: states.ERROR,

    }, { id });

  },

  rematch: (name) => {

    return httpGET('/rematch', {

      fetch: states.LIST_FETCH,
      success: states.REMATCH_SUCCESS,
      error: states.LIST_ERROR,

    }, { name });

  },

  list: (name) => {

    return httpGET('/commodityDemands', {

      fetch: states.LIST_FETCH,
      success: states.LIST_SUCCESS,
      error: states.LIST_ERROR,

    }, { name });

  },

};

export const reducer = (state = initialState, action) => {

  switch (action.type) {

    case states.FETCH:
      return {

        ...state,
        fetchingCount: state.fetchingCount + 1,

        singles: {

          ...state.singles,

          [action.payload.params.id]: {

            ...state.singles[action.payload.params.id],
            fetching: true,

          }

        }

      };

    case states.ERROR:
      return {

        ...state,
        fetchingCount: state.fetchingCount - 1,
        errors: [ ...state.errors, action.payload.error ],

        singles: {

          ...state.singles,

          [action.payload.params.id]: {

            ...state.singles[action.payload.params.id],
            fetching: false,
            error: action.payload.error,

          }

        }

      };

    case states.GET_SUCCESS:
      return {

        ...state,
        fetchingCount: state.fetchingCount - 1,

        singles: {

          ...state.singles,

          [action.payload.params.id]: {

            ...state.singles[action.payload.params.id],
            fetching: false,
            altered: false,
            data: action.payload.response,
            error: null,

          }

        }

      };

    case states.SET:
      return {

        ...state,

        singles: {

          ...state.singles,

          [action.payload.id]: {

            ...state.singles[action.payload.id],
            fetching: false,
            altered: true,
            data: {

              ...state.singles[action.payload.id].data,
              [action.payload.name]: action.payload.value,

            },

          }

        }

      };

    case states.NEW:
      return {

        ...state,

        singles: {

          ...state.singles,

          [action.payload.id]: {

            ...state.singles[action.payload.id],
            fetching: false,
            error: null,
            created: true,
            data: {
              demandEmerged: new Date(),
            },

          }

        }

      };

    case states.CREATE_SUCCESS:
      return {

        ...state,
        fetchingCount: state.fetchingCount - 1,

        singles: {

          ...state.singles,

          [action.payload.params.id]: {

            ...state.singles[action.payload.params.id],
            fetching: false,
            created: false,
            altered: false,
            error: null,

          }

        }

      };

    case states.PERSIST_SUCCESS:
      return {

        ...state,
        fetchingCount: state.fetchingCount - 1,

        singles: {

          ...state.singles,

          [action.payload.params.id]: {

            ...state.singles[action.payload.params.id],
            fetching: false,
            altered: false,
            error: null,

          }

        }

      };

    case states.DELETE_SUCCESS:
      return {

        ...state,
        fetchingCount: state.fetchingCount - 1,

        singles: {

          ...state.singles,

          [action.payload.params.id]: {

            ...state.singles[action.payload.params.id],
            fetching: false,
            altered: false,
            deleted: true,
            error: null,

          }

        }

      };

    case states.LIST_FETCH:
      return {

        ...state,
        fetchingCount: state.fetchingCount + 1,

        collections: {

          ...state.collections,

          [action.payload.params.name]: {

            ...state.collections[action.payload.params.name],
            fetching: true,

          }

        }

      };

    case states.REMATCH_SUCCESS:

      return {

        ...state,
        fetchingCount: state.fetchingCount - 1,

        collections: {

          ...state.collections,

          [action.payload.params.name]: {

            ...state.collections[action.payload.params.name],
            fetching: false,
            
          }

        },

        rematches: {

          ...state.rematches,
          [action.payload.params.name]: action.payload.response

        }

      };

    case states.LIST_SUCCESS:
      return {

        ...state,
        fetchingCount: state.fetchingCount - 1,

        collections: {

          ...state.collections,

          [action.payload.params.name]: {

            ...state.collections[action.payload.params.name],
            fetching: false,
            data: action.payload.response,
            error: null,

          }

        }

      };

    case states.LIST_ERROR:
      return {

        ...state,
        fetchingCount: state.fetchingCount - 1,
        errors: [ ...state.errors, action.payload.error ],

        collections: {

          ...state.collections,

          [action.payload.params.name]: {

            ...state.collections[action.payload.params.name],
            fetching: false,
            error: action.payload.error,

          }

        }

      };


    default:
      return state;

  }

};
