import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { withTranslation, Trans } from "react-i18next";

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { defaultColDef } from '../../../agGridHelpers';

import { matchableValueFormatter, matchableKeyCreator } from '../../../agGridHelpers';

import { actions as CustomerCommodityActions } from '../../../reducer/customer-commodity';

class CustomerCommodityListView extends Component {

  componentDidMount () {

    this.props.dispatch(
      CustomerCommodityActions.list('all_customer_commodities')
    );

  }

  onCellDoubleClicked (eventArgs) {

    this.props.history.push(`customerCommodity/${eventArgs.data.id}`);

  }

  renderList (customerCommoditiesData) {

    const columnDefinitions = [
      { headerName: this.props.i18n.t('label_customer'), field: 'customer', sortable: true, filter: true, valueFormatter: matchableValueFormatter, keyCreator: matchableKeyCreator },
      { headerName: this.props.i18n.t('label_commodity'), field: 'commodity', sortable: true, filter: true, valueFormatter: matchableValueFormatter, keyCreator: matchableKeyCreator },
      { headerName: this.props.i18n.t('label_external_match_code'), field: 'extrnalMatchCode', sortable: true, filter: true },
      { headerName: this.props.i18n.t('label_sales_fixed_price'), field: 'salesFixedPrice', sortable: true, filter: true },
    ];

    return (
      <>
      <div className="pb-space pb-flex">
        <Link className="pb-button" to="/customerCommodity/new">{ this.props.i18n.t('label_create') }</Link>
      </div>

      <div className="ag-theme-balham">
        <AgGridReact
          defaultColDef={ defaultColDef }
          columnDefs={ columnDefinitions }
          rowData={ customerCommoditiesData.data }
          onCellDoubleClicked={ this.onCellDoubleClicked.bind(this) }
        />
      </div>
      </>
    );

  }

  renderFetching () {

    return (<Trans i18nKey="label_fetching" />);

  }

  renderError () {

    return (<Trans i18nKey="label_error" />);

  }

  render () {

    const customerCommodities = { ...this.props.customerCommodityStore.collections['all_customer_commodities'] };

    if (!customerCommodities || customerCommodities.fetching) {

      return this.renderFetching();

    }

    if (customerCommodities.error === null) {

      return this.renderList(customerCommodities);

    }

    return this.renderError();

  }

}

const enhance = compose(
  withTranslation(),
  withRouter,
  connect((store) => ({

    customerCommodityStore: store.CustomerCommodityReducer,

  }))
);

export default enhance(CustomerCommodityListView);
