import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAddressCard,
  faAngleDoubleDown,
  faAngleLeft,
  faArrowAltCircleRight,
  faBoxes,
  faDolly,
  faEdit,
  faFilter,
  faFolderOpen,
  faLink,
  faPlus,
  faSyncAlt,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';

library.add(faAddressCard);
library.add(faAngleDoubleDown);
library.add(faArrowAltCircleRight);
library.add(faAngleLeft);
library.add(faBoxes);
library.add(faDolly);
library.add(faEdit);
library.add(faFilter);
library.add(faFolderOpen);
library.add(faLink);
library.add(faPlus);
library.add(faSyncAlt);
library.add(faTrash);
