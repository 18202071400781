import React, { Component } from 'react';
import { connect } from 'react-redux';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import CellCurrency from '../../section/cell-currency';
import CellAction from '../../section/cell-action';

import { actions as CategoryActions } from '../../../reducer/category';

class CategoryListView extends Component {

  componentDidMount () {

    this.props.dispatch(CategoryActions.list());

  }

  render () {

    const columnDefinitions = [
      { headerName: 'Match Code', field: 'matchCode', sortable: true, filter: true },
      { headerName: 'Name', field: 'name', sortable: true, filter: true },
      { headerName: 'Sales Calculation Percentage', field: 'salesCalculationPercentage', sortable: true, filter: true, cellRendererFramework: CellCurrency },
      { headerName: 'Sales Calculation Absolute', field: 'salesCalculationAbsolute', sortable: true, filter: true, cellRendererFramework: CellCurrency },
      { headerName: 'Actions', cellRendererFramework: CellAction, cellActionOptions: { baseUrl: '/category' } },
    ];

    return (
      <div className="ag-theme-balham">
        <AgGridReact
            columnDefs={ columnDefinitions }
            rowData={ this.props.category.categories } />
      </div>
    );

  }

}

export default connect((store) => ({

  category: store.CategoryReducer,

}))(CategoryListView);
