import React, { Component } from 'react';

import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';

import { withTranslation } from "react-i18next";

import { reducers } from './reducer';

import ScreenDefault from './component/section/screen-default';

import IndexView from './component/view/index';
import DemandListView from './component/view/demand-list';
import DemandDetailView from './component/view/demand-details';
import DemandBulkView from './component/view/demand-bulk';
import CategoryListView from './component/view/category-list';
import CommodityListView from './component/view/commodity-list';
import CommodityDetailsView from './component/view/commodity-details';
import CustomerListView from './component/view/customer-list';
import CustomerDetailsView from './component/view/customer-details';
import CustomerCommodityListView from './component/view/customer-commodity-list';
import CustomerCommodityDetailsView from './component/view/customer-commodity-details';

class App extends Component {

  constructor (props) {
    super(props);

    this.store = createStore(reducers, applyMiddleware(thunk));

    this.changeLanguage = this.changeLanguage.bind(this);

  }

  changeLanguage (language) {

    this.props.i18n.changeLanguage(language);

  }

  render() {
    return (

        <Provider store={this.store}>
          <BrowserRouter>

            <ScreenDefault changeLanguage={ this.changeLanguage }>

              <Route exact path="/" component={ IndexView } />
              <Route exact path="/demand" component={ DemandListView } />
              <Switch>
                <Route exact path="/demand/bulk" component={ DemandBulkView } />
                <Route exact path="/demand/:demandId" component={ DemandDetailView } />
              </Switch>
              <Route exact path="/category" component={ CategoryListView } />
              <Route exact path="/commodity" component={ CommodityListView } />
              <Route exact path="/commodity/:matchableId" component={ CommodityDetailsView } />
              <Route exact path="/customer" component={ CustomerListView } />
              <Route exact path="/customer/:matchableId" component={ CustomerDetailsView } />
              <Route exact path="/customerCommodity" component={ CustomerCommodityListView } />
              <Route exact path="/customerCommodity/:customerCommodityId" component={ CustomerCommodityDetailsView } />

            </ScreenDefault>

          </BrowserRouter>
        </Provider>

    );
  }
}

export default withTranslation()(App);
