import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './style.css';

class CellAction extends Component {

  render () {
    if (!this.props.data) return null;

    return (
      <span className="pb-cell-action">

        <Link className="pb--link-no-special-format" to={ `${this.props.location.pathname}/${this.props.data.id}` }>
          <FontAwesomeIcon icon="edit" />
        </Link>

      </span>
    );

  }

}

CellAction.options = Symbol();

export default withRouter(CellAction);
